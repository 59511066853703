import styled from '@emotion/styled'
import { rhythm } from '../../utils/typography'

export const Title = styled.h1``
export const Text = styled.p``
export const Container = styled.div`
  text-align: center;
  max-width: 600px;
  margin: ${rhythm(2)} auto;
`
export const LightContainer = styled(Container)``
export const DarkContainer = styled(Container)`
  & ${Title} {
    color: white;
  }
  & ${Text} {
    color: white;
  }
`

// TODO Typescript hack to resolve build error
export default () => null
