import Typography from 'typography'

const options = {
  baseFontSize: '18px',
  baseLineHeight: 1.6,
  scaleRatio: 2.25,
  headerFontFamily: ['Roboto', 'sans-serif'],
  headerWeight: '700',
  bodyFontFamily: ['Roboto', 'sans-serif'],
}

const typography = new Typography(options)

// // Hot reload typography in development.
// if (process.env.NODE_ENV !== 'production') {
// 	typography.injectStyles();
// }

export function rhythm(value: number) {
  const result: string = typography.rhythm(value)
  return result
}

export default typography
